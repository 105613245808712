import React, { useEffect, useState, useContext, useCallback } from 'react'
import '../../ManageServices/Services.scss'
import IconArrowLeft from '../../../assets/images/IconArrowLeft.png'
import { useHistory,useLocation } from 'react-router-dom'
import {
  BannerNotification,
  BodyText,
  HeadingLarge,
  InlineLink,
  MetaText,
  PrimaryButton,
  SecondaryButton,
  Spinner,
} from '@hm-group/fabric-components'
import {
  getCountryNamebyCode,
  getBrandIdbyName,
  getDisplayBrandNamebyID,
  getkeyvalueText,
  getBrandImagebyID,
  identifyEvent,
} from '../../../util/helperfunctions'
import moment from 'moment'
import { checkFeedbackStatus, downloadAPData, downloadAPAdditionalData } from '../../../Services/DataService'
import { EVENTACTION, EVENTOUTCOME, EVENTTYPE, PAGE } from '../../../util/constants'
import { AnalyticsContext } from '../../../context/analyticsContext'
import ModalComponent from '../../Modal/ModalComponent'
import APSurveyComponent from '../../../ModalComponents/APSurvey/APSurveyComponent'
import { CustomerContext } from '../../../context/customerDetailsContext'
import { CustomerDataContext } from '../../../context/customerDataContext'
import { UserContext } from '../../../context/userContext'

const DownloadPersonalData = () => {
  const {lslKeyValue} = useContext(UserContext)
  const {customer,updateCustomer} = useContext(CustomerContext)
  const {customerData} = useContext(CustomerDataContext)
  const [isDownloadError, setIsDownloadError] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  

  const {
    businessPartnerId,
    clubRole,
    emailAddress,
    corporateBrandId,
    countryCode,
    apDownloadDataExpiresOn,
    caseidentifier,
    caseIdentifier = caseidentifier,  // Use caseIdentifier if it exists, otherwise fallback to caseidentifier
  } = customer;
  const { guestAPDownloadDataExpiresOn, guestBusinessPartnerId, guestAPCaseIdentifier } = customerData

  const { analyticsData, updateAnalyticsData } = useContext(AnalyticsContext)

  const history = useHistory()
  const location = useLocation()

  const isshowkey = false // temporary

  const { isGuest = false, isApLogin = false } = location.state || {};

  const language = lslKeyValue && lslKeyValue.preferredLanguage
  const isAddExtract = location.state && location.state.aprequesttype == 'ADDITIONAL'

  let linkLabel =
    lslKeyValue && lslKeyValue.lslKeyData
      ? getkeyvalueText('A&PDownloadPage', 'gotolabel', isshowkey, lslKeyValue.lslKeyData)
      : ''
  const mpdLinkGoto = linkLabel && linkLabel.split('{mpdlink}')[0]
  const apAddFileType = location.state && location.state.apAddFileType
  const dateText = lslKeyValue && lslKeyValue.lslKeyData ? getkeyvalueText('A&PDownloadPage', 'a&pdatareadyLabel', isshowkey, lslKeyValue.lslKeyData).split(
    '{dd mmm yyyy}',
  )[1] : ''

  const DOMAIN_URL = process.env.REACT_APP_DOMAIN_URL

  const handleDownloadData = async (value) => {
    const downloadRequest = {
      emailid: emailAddress && emailAddress.toLowerCase(),
      countryCode: countryCode,
      brandId: corporateBrandId,
      preferredLanguage: language,
      caseIdentifier,
      bpid: businessPartnerId,
    }

    if (isGuest) {
      downloadRequest.caseIdentifier = guestAPCaseIdentifier
      downloadRequest.bpid = guestBusinessPartnerId
    }

    try {
      setIsDownloadError(false)
      setIsLoading(true)
      const response = isAddExtract ? await downloadAPAdditionalData(downloadRequest, value) : await downloadAPData(downloadRequest, value)
      let downloadType = value == 'xml_button' ? isAddExtract ? EVENTACTION.apAddDownloadXML : EVENTACTION.apDownloadXML : isAddExtract ? EVENTACTION.apAddDownloadPDF : EVENTACTION.apDownloadPDF
      setIsLoading(false)
      if (response.status == 200) {
        const link = document.createElement('a')
        link.href =
          value == 'xml_button'
            ? `${DOMAIN_URL}Customer_Data_Portable_${downloadRequest.bpid}.xml`
            : `${DOMAIN_URL}Customer_Data_Readable_${downloadRequest.bpid}.pdf`
        link.target = '_blank'
        if (value == 'xml_button') {
          link.setAttribute('download', `Customer_Data_Portable_${downloadRequest.bpid}.xml`)
        } else {
          link.setAttribute('download', `Customer_Data_Readable_${downloadRequest.bpid}.pdf`)
        }
        document.body.appendChild(link)
        link.click()
      }

      updateAnalyticsData({
        ...analyticsData,
        managedCountry: countryCode,
        managedBrand: corporateBrandId,
        eventType: isAddExtract ? EVENTTYPE.apAddDownload : EVENTTYPE.apDownload,
        page: isAddExtract? PAGE.apAddDownloadPage : PAGE.apDownloadPage,
        eventAction: downloadType,
        eventOutcome: EVENTOUTCOME.success,
        managedBPID: businessPartnerId,
        managedCustomerType: clubRole,
        ...(isApLogin && { sourceBPID: businessPartnerId , sourceCustomerType: clubRole }),
      })
    } catch (error) {
      updateAnalyticsData({
        ...analyticsData,
        managedCountry: countryCode,
        managedBrand: corporateBrandId,
        eventType: isAddExtract ? EVENTTYPE.apAddDownload : EVENTTYPE.apDownload,
        page: isAddExtract? PAGE.apAddDownloadPage : PAGE.apDownloadPage,
        eventAction: isAddExtract ? EVENTACTION.apAddDownloadPDF : EVENTACTION.apDownloadPDF,
        eventOutcome: EVENTOUTCOME.failure,
        managedBPID: businessPartnerId,
        managedCustomerType: clubRole,
        ...(isApLogin && { sourceBPID: businessPartnerId , sourceCustomerType: clubRole }),
      })
      // Handle the error
      setIsLoading(false)
      setIsDownloadError(true)
    }
  }

  const handleRedirectMPDDashboard = (linkType) => {
    if (linkType == 'back_btn') {
      history.push({ pathname: '/ManageServices' })
    } else {
      updateCustomer({})
      history.push({ pathname: '/dashboard' })
    }
  }

  const renderLoader = () => {
    return (
      <div className="loader_section_download">
        <Spinner color={'black'} isRendered />
      </div>
    )
  }

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [apFeedback, setAPFeedback] = useState({
    feedbackStatus: false,
    isFeedbackSubmitted: undefined,
  })

  useEffect(async () => {

    if (businessPartnerId && (caseIdentifier || guestAPCaseIdentifier) ) {

    
      try {
        const feedbackStatus = await checkFeedbackStatus({
          bpID : isGuest ? guestBusinessPartnerId :  businessPartnerId,
          caseIdentifier : isGuest ? guestAPCaseIdentifier : caseIdentifier,
          value: apFeedback.feedbackStatus,
        })

        if (feedbackStatus.status === 200) {
          setAPFeedback((prev) => {
            return {
              ...prev,
              isFeedbackSubmitted: feedbackStatus.data.status === 'true' ? true : false,
            }
          })
        } else {
          setAPFeedback((prev) => {
            return {
              ...prev,
              isFeedbackSubmitted: false,
            }
          })
        }
      } catch (error) {
        setAPFeedback((prev) => {
          return {
            ...prev,
            isFeedbackSubmitted: false,
          }
        })
      }
    }
  }, [businessPartnerId, caseIdentifier, apFeedback.feedbackStatus,guestAPCaseIdentifier])

  const sendAPSurveyAnalyticsData = useCallback((eventAction, eventOutcome) => {
    updateAnalyticsData({
      ...analyticsData,
      managedCountry: countryCode,
      managedBrand: corporateBrandId,
      eventType: EVENTTYPE.apSurvey,
      page: PAGE.apSurveyPage,
      eventAction,
      eventOutcome,
      managedBPID: businessPartnerId,
      managedCustomerType: clubRole,
      ...(isApLogin && { sourceBPID: businessPartnerId , sourceCustomerType: clubRole }),
    })
  }, [])

  const renderApDownloadPDFBtn = () => {
    return (
      <>
        <MetaText Tag="p" className="download-label">
          {getkeyvalueText('A&PDownloadPage', 'a&pdataaspdfLabel', isshowkey, lslKeyValue.lslKeyData)}
        </MetaText>
        <PrimaryButton
          className="download__btn"
          data-testid="download_pdf_data_btn"
          onClick={() => handleDownloadData('pdf_button')}
          isDisabled={isLoading}
        >
          {getkeyvalueText('A&PDownloadPage', 'a&pdownloadpdfLabel', isshowkey, lslKeyValue.lslKeyData)}
        </PrimaryButton>
      </>
    )
  }

  const renderApDownloadXMLBtn = () => {
    return (
      <>
        <MetaText Tag="p" className="download-label">
          {getkeyvalueText('A&PDownloadPage', 'a&pdownloaddatatoxmlLabel', isshowkey, lslKeyValue.lslKeyData)}
        </MetaText>
        <SecondaryButton
          className="download__btn"
          data-testid="download_xml_data_btn"
          isDisabled={isLoading}
          onClick={() => handleDownloadData('xml_button')}
        >
          {getkeyvalueText('A&PDownloadPage', 'a&pdownloadxmlLabel', isshowkey, lslKeyValue.lslKeyData)}
        </SecondaryButton>
      </>
    )
  }

  const renderDownloadBtns = () => {
    return (
      <>
        {apAddFileType ? (apAddFileType == 'PDF' || apAddFileType == 'BOTH' ? renderApDownloadPDFBtn() : '') : renderApDownloadPDFBtn()}
          {isLoading ? renderLoader() : ''}
        {apAddFileType ? (apAddFileType == 'XML' || apAddFileType == 'BOTH' ? renderApDownloadXMLBtn() : '') : renderApDownloadXMLBtn()}

        </>
    )
  }

  const handleFeedbackClose = useCallback((isSuccess) => {
    if (isSuccess) {
      setAPFeedback((prev) => {
        return {
          ...prev,
          feedbackStatus: true,
        }
      })
    }
    if (!isSuccess) {
      sendAPSurveyAnalyticsData(EVENTACTION.apSurveyCancel, EVENTOUTCOME.success)
    }
    setIsModalOpen(false)
  }, [])

  const FeedbackSection = () => {
    const text = getkeyvalueText('A&PDownloadPage', 'a&pleavefeedbackLabel', isshowkey, lslKeyValue.lslKeyData)
    const parts = text.split(/(?:\{0\}|\{1\})/)

    return (
      <p className="apFeedback download-label">
        <BodyText className="displayInline" Tag="p">
          {parts[0]}
        </BodyText>
        <InlineLink
          className="displayInline"
          title="title"
          onClick={() => {
            setIsModalOpen(true)
            sendAPSurveyAnalyticsData(EVENTACTION.apSurveyLink, EVENTOUTCOME.success)
          }}
          onKeyDown={(event) => {
            identifyEvent(event) && setIsModalOpen(true)
          }}
        >
          {parts[1]}
        </InlineLink>
        <BodyText className="displayInline" Tag="p">
          {parts[2]}
        </BodyText>
      </p>
    )
  }

  const formatDateText = () => {
    if(lslKeyValue && lslKeyValue.lslKeyData){
    const timestamp = isGuest ? guestAPDownloadDataExpiresOn : apDownloadDataExpiresOn;
    const formattedDate =   moment.unix(timestamp).format('DD MMMM YYYY');

    const monthName = moment(formattedDate).format('MMMM');

    // Retrieve the text with placeholders
    const baseText = getkeyvalueText('A&PDownloadPage', 'a&pdatareadyLabel', isshowkey, lslKeyValue.lslKeyData);

    // Split the text at the placeholder
    const [beforeDate, afterDate] = baseText.split('{dd mmm yyyy}');

    // Replace the month in the formatted date
    const monthText = getkeyvalueText('Month', monthName, isshowkey, lslKeyValue.lslKeyData);
    const formattedText = `${beforeDate}${formattedDate.replace(monthName, monthText)}${afterDate === '.'? '': ' '}${afterDate.trim()}`;

    // Return the formatted text with punctuation handling
    return afterDate.trim() === '' ? `${formattedText}.` :  `${formattedText}`;
    }
};

  return (
    <div>
      <ModalComponent
        children={
          <APSurveyComponent
            onClose={handleFeedbackClose}
            payloadData={{
              bpId: isGuest ? guestBusinessPartnerId :  businessPartnerId,
              caseIdentifier :isGuest ? guestAPCaseIdentifier : caseIdentifier,
              country : countryCode,
              brand: corporateBrandId,
              emailId: emailAddress,
            }}
            sendAPSurveyAnalyticsData={sendAPSurveyAnalyticsData}
          />
        }
        onClose={handleFeedbackClose}
        openModal={isModalOpen}
      />
      <div className="manage__services_section">
        {isApLogin ? (
          ''
        ) : (
          <div className="overview__section">
            <div
              className="overview_control_section"
              onClick={() => {
                handleRedirectMPDDashboard('back_btn')
              }}
              onKeyDown={(event) => {
                identifyEvent(event) && handleRedirectMPDDashboard('back_btn')
              }}
            >
              <img src={IconArrowLeft} alt="BackToOverview" />
              <BodyText Tag="p" className="overview__section_text">
                {getkeyvalueText('ManageServices', 'bckLabel', isshowkey, lslKeyValue.lslKeyData)}
              </BodyText>
            </div>
          </div>
        )}

        {isDownloadError || apFeedback.feedbackStatus ? (
          <div className={'notification__section'}>
            <BannerNotification
              closeLabel="Close"
              message={getkeyvalueText(
                isDownloadError ? 'accessPersonalData' : 'Lightboxes',
                isDownloadError ? 'accessdataerrormsgLabel' : 'thanksfeedbackHeaderLabel',
                isshowkey,
                lslKeyValue.lslKeyData,
              )}
              severity={isDownloadError ? 'error' : 'success'}
            />
          </div>
        ) : (
          ''
        )}

        <div className="brandLogo__title_section">
          <div className="brand__img">
            <img className="brand_puff_logo" src={getBrandImagebyID(corporateBrandId)} alt={corporateBrandId}></img>
          </div>
          <div className="edit__text" data-testid="edit__text">
            <HeadingLarge Tag="h1">
              {getkeyvalueText('A&PDownloadPage', `${isAddExtract ? 'a&padditionalDownloadDataLabel' : 'a&pdownloadDataLabel'}`, isshowkey, lslKeyValue.lslKeyData)
                .replace('{Brand}', `${getDisplayBrandNamebyID(corporateBrandId)}`)
                .replace('{Market}', `${getCountryNamebyCode(countryCode, isshowkey, lslKeyValue.lslKeyData)}`)}
            </HeadingLarge>
          </div>
        </div>
        <div className="services__box">
          <div className="helper__text" data-testid="helper__text">
            <BodyText Tag="p">{formatDateText()}</BodyText>
            <br />
            <BodyText Tag="p">
              {getkeyvalueText('A&PDownloadPage', 'downloadwarningmsg', isshowkey, lslKeyValue.lslKeyData)}
            </BodyText>
          </div>
          

          {renderDownloadBtns()}

          {isApLogin ? (
            <div className="mpd-dashboard-link">
              <BodyText Tag="p">{mpdLinkGoto}</BodyText>
              &nbsp;
              <InlineLink
                iconPosition="start"
                title="title"
                data-testid="my_personal_data_link"
                target="_blank"
                // className="mpd-dashboard-link"
                onClick={() => {
                  handleRedirectMPDDashboard()
                }}
                onKeyDown={(event) => {
                  identifyEvent(event) && handleRedirectMPDDashboard()
                }}
              >
                {getkeyvalueText('login', 'mpd_heading', isshowkey, lslKeyValue.lslKeyData)}
              </InlineLink>
            </div>
          ) : (
            ''
          )}
          {apFeedback.isFeedbackSubmitted === false ? (
            <div className="apFeedback download-label">
              <FeedbackSection />
            </div>
          ) : (
            ''
          )}
        </div>
      </div>
    </div>
  )
}

export default DownloadPersonalData
