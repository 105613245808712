import React, { useState, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { getkeyvalueText, identifyEvent } from '../../../util/helperfunctions'
import { InfoIcon } from '@hm-group/fabric-icons'
import LightBox from '../../LightBoxes/LightBox'
import moment from 'moment'
import { Accordion, BodyText, HeadingSmall, GhostLink } from '@hm-group/fabric-components'
import { CUSTOMERTYPE, EVENTACTION, EVENTOUTCOME, EVENTTYPE, PAGE, brandNameFromID } from '../../../util/constants'
import { AnalyticsContext } from '../../../context/analyticsContext'
import { CustomerContext } from '../../../context/customerDetailsContext'
import { CustomerDataContext } from '../../../context/customerDataContext'
import { UserContext } from '../../../context/userContext'

const DeleteAccountSection = ({ updateExpanded, accordianExpanded }) => {
  const history = useHistory()
  const {lslKeyValue} = useContext(UserContext)
  const { analyticsData, updateAnalyticsData } = useContext(AnalyticsContext)
  const { customer } = useContext(CustomerContext)
  const { customerData } = useContext(CustomerDataContext)

  const [isOpen, setIsOpen] = useState(false)
  const [type, setType] = useState('')
  const isshowkey = false // temporary

  const {
    businessPartnerId,
    clubRole,
    emailAddress,
    corporateBrandId,
    countryCode,
    consentRevocationDate,
    onlineOMSCustomerIdentifier,
    revokeConsent,
  } = customer
  const { guestBusinessPartnerId, guestCICFlag, guestCICDate } = customerData

  const momentDate = consentRevocationDate && moment(new Date(consentRevocationDate))
  const guestMomentDate = guestCICDate && moment(new Date(guestCICDate))

  const handleClose = () => {
    setIsOpen(false)
  }

  const handleAnchorClick = (isGuest) => {
    if (isGuest) {
      setType('customerService')
      setIsOpen(true)
    }
    updateAnalyticsData({
      ...analyticsData,
      managedCountry: countryCode,
      managedBrand: corporateBrandId,
      eventType: EVENTTYPE.cic,
      page: PAGE.deleteAccountPage,
      eventAction: EVENTACTION.deletePersonalDataReadMore,
      eventOutcome: EVENTOUTCOME.success,
      managedBPID: isGuest ? guestBusinessPartnerId : businessPartnerId,
      managedCustomerType: isGuest ? CUSTOMERTYPE.guest : clubRole,
    })
    if (!isGuest) {
      history.push({ pathname: '/deletePersonalData' })
    }
  }

  const renderContinue = (isGuest) => (
    <GhostLink
      onClick={() => {
        handleAnchorClick(isGuest)
      }}
      onKeyDown={(event) => {
        if (identifyEvent(event)) handleAnchorClick(isGuest)
      }}
      tabIndex={0}
    >
      {getkeyvalueText('commonKey', 'continuebtn', isshowkey, lslKeyValue.lslKeyData)}
    </GhostLink>
  )

  const renderDeleteRequestStatusText = (date) => (
    <div
      className="services_request"
      onClick={() => {
        setType('notifydelete')
        setIsOpen(true)
      }}
    >
      <InfoIcon size="small" className="infoIconMargin" />
      <BodyText Tag="p" className="services_deletion_text">
        {
          getkeyvalueText('LandingPage', 'deleterequestLabel', isshowkey, lslKeyValue.lslKeyData).split(
            '{dd mmm yyyy}',
          )[0]
        }

        {String(moment(new Date(date)).format('DD MMMM YYYY')).replace(
          moment(new Date(date)).format('MMMM'),
          getkeyvalueText('Month', moment(new Date(date)).format('MMMM'), isshowkey, lslKeyValue.lslKeyData),
        )}

        {
          getkeyvalueText('LandingPage', 'deleterequestLabel', isshowkey, lslKeyValue.lslKeyData).split(
            '{dd mmm yyyy}',
          )[1]
        }

      </BodyText>

    </div>
  )

  return (
    <div className="delete__account_section">
      <Accordion
        isExpanded={accordianExpanded['deleteAccountAccord']}
        onClick={() => updateExpanded('deleteAccountAccord')}
        accordionItems={[
          {
            content: (
              <div>
                {/* account section */}
                <div>
                  <div className="access__services_text services_msg">
                    <BodyText Tag="p">
                      {getkeyvalueText('ManageServices', 'erasedataLabel', isshowkey, lslKeyValue.lslKeyData)}
                    </BodyText>
                  </div>
                  <div className="align-manage-items">
                    <span>
                      <HeadingSmall>
                        {clubRole === 'z002'
                          ? getkeyvalueText('LandingPage', 'holdmembershipLabel', isshowkey, lslKeyValue.lslKeyData)
                          : clubRole === 'z004' || (clubRole === 'z003' && onlineOMSCustomerIdentifier)
                          ? getkeyvalueText('LandingPage', 'holdaccntLabel', isshowkey, lslKeyValue.lslKeyData)
                          : ''}
                      </HeadingSmall>
                    </span>
                    {!revokeConsent && renderContinue(false)}
                  </div>

                  {/* account status text */}
                  {revokeConsent && momentDate && renderDeleteRequestStatusText(momentDate)}
                </div>
                {/* guest account section */}
                {guestBusinessPartnerId && (
                  <div>
                    <div className="align-manage-items">
                      <span>
                        <HeadingSmall Tag="h1">
                          {getkeyvalueText('LandingPage', 'holdguestaccntLabel', isshowkey, lslKeyValue.lslKeyData)}
                        </HeadingSmall>
                      </span>
                      {!guestCICFlag && renderContinue(true)}
                    </div>

                    {/* guest account status text */}
                    {guestCICFlag && guestMomentDate && renderDeleteRequestStatusText(guestMomentDate)}
                  </div>
                )}
              </div>
            ),
            heading: (
              <BodyText Tag="p">
                {getkeyvalueText('ManageServices', 'requestdeletiondatah2ucLabel', isshowkey, lslKeyValue.lslKeyData)}
              </BodyText>
            ),
            id: '1',
          },
        ]}
        defaultId="0"
        shouldCollapseSibling
        variant="fill"
      />
      <LightBox
        brand={brandNameFromID[corporateBrandId]}
        country={countryCode}
        open={isOpen}
        handleClose={handleClose}
        commonRedirect={handleClose}
        type={type}
        userName={emailAddress}
        isshowkey={isshowkey}
      />
    </div>
  )
}

export default DeleteAccountSection
